import React from 'react'
import Helmet from 'react-helmet'
import { Link } from "gatsby";

import "../components/Layout/layout.css";

if (typeof window !== "undefined") {
  var accessKeyValue = localStorage.getItem('GOLF4CHANGE_ACCESS_KEY');
}



export default function FourOhFour() {

    return (
      <>
      <Helmet>
          <body class='page_404' />
      </Helmet>
      <div className="home_intro_copy">
        <h1>FORE!! OH... FORE</h1>
        <p>It looks like your looking for a page that doesnt exist!</p>
        <Link className="fourButton" to="/">
      Take me back to the starting Tee
       </Link>
      </div>
      
      </>
    );
  }
